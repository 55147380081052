import Drawer from "../../components2/Drawer/Drawer";
import React from "react";
import Warning from "./Warning";

const WarningModal = ({
        toggle = false,
        handleClose = () =>{},
        isModal = false,
        userId = "",
        username = ""
    }) => {
    return isModal ? (
        <Drawer
            large
            open={toggle}
            handleClose={handleClose}
            headContent="Messages de modération"
        >
            <Warning
                toggle={toggle}
                handleClose={handleClose}
                userId={userId}
                username={username}
            />
        </Drawer>
    ) : (
        <Warning
            toggle={toggle}
            handleClose={handleClose}
            userId={userId}
            username={username}
        />
    )
}

export default WarningModal;