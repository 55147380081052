export default function convertToNext(editorState) {
    let res = {
        text : ""
    }
    if (editorState && typeof editorState.toJSON === "function"){
        let editorObj = editorState.toJSON();
        let nodes = editorObj.root.children[0].children;
        let currentOffset = 0;
        nodes.forEach(node=>{
            if (node.type === "mention"){
                res.text = res.text + node.text;
                if (!Array.isArray(res.mentions)) res.mentions = [];
                res.mentions.push({
                    offset : currentOffset,
                    length : node.text.length,
                    user : node.mentionUserId
                })
            }else if (node.type === "linebreak"){
                res.text = res.text + '\n';
            }else if((node.type === "link") || (node.type === "autolink")){
                let text = node.children.map(item => item.text).join('');
                res.text = res.text + text;
                if (!Array.isArray(res.links)) res.links = [];
                res.links.push({
                    offset : currentOffset,
                    length : text.length,
                    url : node.url,
                    rel : node.rel,
                    target : node.target
                });

            } else if (!!node.text) {
                res.text = res.text + node.text;
            }
            currentOffset = res.text.length;
        })
    }
    return res
}