import React from 'react';
import "./Alert.scss";
import Button, {BUTTON_VARIANTS} from "../../components2/UI/Button/Button";
import Group from "../../components2/UI/Group/Group";

const Alert = ({
       text = "",
       handleClose = () =>{},
       handleDelete = () =>{}
   }) => {
    return (
        <div className="alert">
            <div className="alert-title">
                {text}
            </div>
            <div className="alert-footer">
                <Group>
                    <Button
                        onClick={() => handleClose()}
                    >
                        Annuler
                    </Button>
                    <Button
                        variant={BUTTON_VARIANTS.REVERSE}
                        onClick={() => handleDelete()}
                    >
                        Confirmer
                    </Button>
                </Group>
            </div>
        </div>
    )
};

export default Alert;
