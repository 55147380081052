import {useQuery, useQueryClient} from "@tanstack/react-query";
import {fetchUserCurrent, USER_CURRENT_CLICK_CTA_KEY, USER_CURRENT_KEY} from "../api/public-voissa/users";
import createLink from "../libs/createLink";
import ability from "../ability";
import {useNavigate} from "react-router-dom";
import useToken from "../hooks/token";
import _ from "lodash";
import qs from "qs";
import fnClickCta from "../libs/clickCta";


const useCurrentUser = ({
        enabled = false,
        onSuccess = () =>{}
    }) =>{
    const queryClient = useQueryClient();
    const [token, setToken] = useToken();
    const dataCurrentUser = queryClient.getQueryData([USER_CURRENT_KEY]);
    const currentRole = _.get(dataCurrentUser, 'role');
    const navigate = useNavigate();


    const {refetch : refetchClickCTA} = useQuery(
        [USER_CURRENT_CLICK_CTA_KEY],
        ()=> fnClickCta(dataCurrentUser),
        {
            staleTime : ( 60 * 60 * 2),
            enabled : !!_.get(dataCurrentUser,'_id') ,
            placeholderData : fnClickCta(dataCurrentUser)
        }
    );

    return useQuery(
        [USER_CURRENT_KEY],
        ()=> fetchUserCurrent(),
        {
            staleTime : (1000 * 60 * 2),
            enabled,
            refetchOnWindowFocus: true,
            onSuccess : async (currentUser) =>{
                let isAlert = sessionStorage.getItem(`${process.env.REACT_APP_TOKEN_NAME}_alert`);
                onSuccess(currentUser);
                if(currentUser?._id) {
                    window._userId = currentUser._id;
                    if (!currentUser?.subscription?.hasAccess ) {
                        if([2,3,4].includes(currentUser.profile.gender)){
                            queryClient.setQueryData([USER_CURRENT_KEY], oldData => {
                                return {
                                    ...oldData,
                                    modalSubscribed : {
                                        modal : false,
                                        modalName : "",
                                        link : createLink("userMeSettingsAbonnement", oldData)
                                    }
                                }
                            });
                        }
                        else{
                            queryClient.setQueryData([USER_CURRENT_KEY], oldData => {
                                return {
                                    ...oldData,
                                    modalSubscribed : {
                                        modal : false,
                                        modalName : "",
                                        link : createLink("subscription")
                                    }
                                }
                            });
                        }
                    }
                    if (currentUser.role !== "emailUnconfirmed" && !currentUser.profile.isCompleted && !isAlert) {
                        let {aToken, actionToken} = qs.parse(window.location.search, {ignoreQueryPrefix: true});
                        if((aToken || actionToken) && window.location.pathname !== '/first-connexion'){
                            sessionStorage.setItem(`${process.env.REACT_APP_TOKEN_NAME}_alert`, true);
                            navigate('/first-connexion');
                            //window.location.replace('/first-connexion');
                        }
                    }
                }

                if(currentUser?.rules && (!dataCurrentUser || currentRole !== currentUser.role)){
                    ability.update(currentUser.rules);
                    await queryClient.invalidateQueries([USER_CURRENT_CLICK_CTA_KEY]);
                    await refetchClickCTA();
                }
                queryClient.setQueryData([USER_CURRENT_KEY], oldData => {
                    delete oldData.rules;
                    return oldData
                });
            },
            onError : (error) =>{
                if(_.get(error, 'statusCode') === 404){
                    console.log(token);
                    setToken(null);
                    navigate("/")
                }
            },
            //notifyOnChangeProps : ['data','error']
        }
    );
}

export default useCurrentUser;