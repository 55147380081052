import { uid } from "uid";

export function request(req){
    let sessionUid = localStorage.getItem('sessionUid');
    if (!sessionUid) {
        sessionUid = uid();
        localStorage.setItem('sessionUid', sessionUid);
    }
    if (sessionUid != null) {
        req.headers.session_uid = sessionUid;
    }
    return req;
}

const tokenInterceptor = {
    request
};

export default tokenInterceptor;