import React from 'react';
import Icon from './Icon';
import classNames from 'classnames';

class Popup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false
        };

        this.togglePopup = this.togglePopup.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
    }

    togglePopup() {
        this.setState({toggle: !this.state.toggle});
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    render() {
        return this.props.toggle ? (
            <div
                className={classNames('popup', `${this.props.className ? this.props.className : ""}`, {'light': !!this.props.light})}
                >
                <div className="popup-outsideclick" onClick={this.props.handleClose}/>
                <span className="close-popup" onClick={this.props.handleClose}><Icon type="close" /></span>
                <div
                    className="popup-container"
                    onClick={(e) => e.stopPropagation()}
                    ref={this.setWrapperRef}
                >
                        {this.props.children}
                </div>
            </div>
        ) : null
    }
}

export default Popup;
