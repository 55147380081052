import React from 'react';
import {faBell, faCog, faSlidersH, faLaptop, faTransgenderAlt, faSteeringWheel,faInterrobang, faArchive, faStar, faImages, faImage, faStopwatch, faPaperclip, faTemperatureHigh, faVenusMars, faUser, faGavel, faMapPin, faTools, faSuitcase, faTshirt, faNetworkWired, faBookmark, faComment, faHeart, faCamera, faLock, faUnlockAlt, faGlobe, faUsers, faChevronDown, faChevronUp, faChevronLeft, faChevronRight, faTimes, faArrowUp, faCheck, faCheckCircle, faEye, faEyeSlash, faSearch, faPencilAlt, faAngleRight, faAngleLeft, faLink, faComments, faMars, faMale, faVenus, faFemale, faUserFriends, faMarsDouble, faVenusDouble, faTransgender, faSmile, faTrash, faFlag, faPlus, faPlusCircle, faUserPlus, faUserCircle, faCircleNotch, faPlay, faCaretUp, faCaretRight, faCaretLeft, faEnvelope, faPaperPlane, faTags, faSyncAlt, faCrop, faCompress, faExpandArrowsAlt, faArrowCircleLeft, faArrowDown, faBars, faArrowLeft, faArrowRight, faInfoCircle, faCommentDots, faCommentAlt, faShareAlt, faQuestion, faBan, faCoffee, faClock, faInfo, faFile, faNewspaper, faStream, faEllipsisH, faEllipsisV, faSignOutAlt, faShoppingCart, faVideo, faBook, faLongArrowAltRight, faThumbtack, faPlayCircle, faMobileAlt, faCertificate, faHome, faMapMarkerAlt, faBirthdayCake, faLocationArrow, faBullhorn, faThumbsUp, faStickyNote, faFilm, faFire, faMask, faHandcuffs, faTree, faPaw, faQuoteLeft, faEnvelopeOpen, faQuestionCircle, faUserSecret, faHandHoldingUsd, faFolder, faPalette, faPoll, faUpload, faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/pro-solid-svg-icons';

// Mapping de tous les types à leurs icônes correspondantes
const iconMapping = {
    archive: faArchive,
    star: faStar,
    medias: faImages,
    "big-arrow-right": faArrowRight,
    "archive-2": faArchive,
    "picture-2": faImage,
    "arrow-right-2": faArrowRight,
    "stop-watch": faStopwatch,
    attach: faPaperclip,
    temperature: faTemperatureHigh,
    genre: faVenusMars,
    corps: faUser,
    fetichisme: faGavel,
    lieu: faMapMarkerAlt,
    position: faMapPin,
    pratique: faTools,
    accessoire: faSuitcase,
    tenue: faTshirt,
    network: faNetworkWired,
    bookmark: faBookmark,
    "bookmark-add": faBookmark,
    "bookmark-remove": faBookmark,
    "notif-chat": faComment,
    "notif-rencontre": faHeart,
    "notif-voissnap": faCamera,
    camera: faCamera,
    "chat-webcam": faVideo,
    lock: faLock,
    unlock: faUnlockAlt,
    world: faGlobe,
    group: faUsers,
    "chevron-down": faChevronDown,
    "chevron-up": faChevronUp,
    "chevron-left": faChevronLeft,
    "chevron-right": faChevronRight,
    close: faTimes,
    "close-x": faTimes,
    "arrow-up": faArrowUp,
    "star-full": faStar,
    "star-empty": faStar,
    "heart-full": faHeart,
    "heart-empty": faHeart,
    bell: faBell,
    comment: faComment,
    cog: faCog,
    sliders: faSlidersH,
    check: faCheck,
    "check-circle": faCheckCircle,
    "check-circle-o": faCheckCircle,
    eye: faEye,
    "eye-cut": faEyeSlash,
    search: faSearch,
    "pencil-square": faPencilAlt,
    pencil: faPencilAlt,
    "angle-right": faAngleRight,
    "angle-left": faAngleLeft,
    link: faLink,
    "comments-o": faComments,
    mars: faMars,
    man: faMale,
    venus: faVenus,
    woman: faFemale,
    "venus-mars": faVenusMars,
    couple: faUserFriends,
    "mars-double": faMarsDouble,
    "couple-gay": faUserFriends,
    "venus-double": faVenusDouble,
    "couple-lesbian": faUserFriends,
    transgender: faTransgender,
    trav: faTransgender,
    smile: faSmile,
    trash: faTrash,
    report: faFlag,
    plus: faPlus,
    "plus-circle": faPlusCircle,
    user: faUser,
    "user-o": faUser,
    "user-circle": faUserCircle,
    "user-plus": faUserPlus,
    picture: faImage,
    "circle-o-notch": faCircleNotch,
    play: faPlay,
    "caret-up": faCaretUp,
    "caret-right": faCaretRight,
    "caret-left": faCaretLeft,
    "envelope": faEnvelope,
    "paper-plane": faPaperPlane,
    "paperclip": faPaperclip,
    "tags": faTags,
    "rotate": faSyncAlt,
    "crop": faCrop,
    "compress": faCompress,
    "arrows-alt": faExpandArrowsAlt,
    "arrow-circle-left": faArrowCircleLeft,
    "arrow-down": faArrowDown,
    "hamburger-menu": faBars,
    "arrow-left": faArrowLeft,
    "arrow-right": faArrowRight,
    "info-circle": faInfoCircle,
    "speech-bubbles": faCommentDots,
    "speech-bubble": faCommentAlt,
    "share": faShareAlt,
    "question": faQuestion,
    "ban": faBan,
    "coffee": faCoffee,
    "clock-warn": faClock,
    "info": faInfo,
    "media": faFile,
    "article": faNewspaper,
    "timeline": faStream,
    "horizontal-dots": faEllipsisH,
    "vertical-dots": faEllipsisV,
    "sign-out": faSignOutAlt,
    "shopping-cart": faShoppingCart,
    "shopping-cart-c": faShoppingCart,
    "video-camera": faVideo,
    "video-camera-c": faVideo,
    "book": faBook,
    "book-c": faBook,
    "long-arrow-right": faLongArrowAltRight,
    "thumb-tack": faThumbtack,
    "play-video": faPlayCircle,
    "minimize": faMinus,
    "mobile": faMobileAlt,
    "certificate": faCertificate,
    "navbar-home": faHome,
    "navbar-photos": faImages,
    "navbar-videos": faVideo,
    "navbar-forum": faComments,
    "navbar-rencontre": faHeart,
    "laptop": faLaptop,
    "map-marker": faMapMarkerAlt,
    "age": faBirthdayCake,
    "location-arrow": faLocationArrow,
    "bulhorn": faBullhorn,
    "thumbs-up": faThumbsUp,
    "sticky-note": faStickyNote,
    "picture-bw": faImage,
    "picture-c": faImage,
    "film-reel-bw": faFilm,
    "film-reel": faFilm,
    "heart-c": faHeart,
    "comment-c": faComment,
    "add-user-c": faUserPlus,
    "article-bw": faNewspaper,
    "article-c": faNewspaper,
    "meet-c": faHeart,
    "meet-bw": faHeart,
    "camera-bw": faCamera,
    "camera-c": faCamera,
    "timeline-bw": faStream,
    "timeline-c": faStream,
    "home-bw": faHome,
    "home-c": faHome,
    "forum-bw": faComments,
    "forum-c": faComments,
    "fire": faFire,
    "messenger-bw": faComment,
    "messenger-c": faComment,
    "mask": faMask,
    "handcuffs": faHandcuffs,
    "woman-couple": faVenusMars,
    "threesome": faVenusMars,
    "him-to-her": faMars,
    "her-to-him": faVenus,
    "bestial": faPaw,
    "tout-en-douceur": faHeart,
    "a-plusieurs": faUsers,
    "public-sex": faVenusMars,
    "home-sex": faHome,
    "outdoor-sex": faTree,
    "feminine-caress": faVenus,
    "man-couple": faVenusMars,
    "trans-video": faTransgenderAlt,
    "video-editing": faVideo,
    "masculin-caress": faMars,
    "timeline-status": faStream,
    "voissa-media": faFile,
    "delete-message": faTrash,
    "quote-message": faQuoteLeft,
    "user-bw": faUser,
    "user-c": faUser,
    "user-online": faUser,
    "envelope-o": faEnvelopeOpen,
    "envelope-o-open": faEnvelopeOpen,
    "close-group": faTimes,
    "forum-simple": faComments,
    "question-circle": faQuestionCircle,
    "unclassifiable": faQuestion,
    "chat-double-o": faComment,
    "chat-double": faComment,
    "chat-o": faComment,
    "steering-wheel": faSteeringWheel,
    "incognito": faUserSecret,
    "secure": faLock,
    "free": faHandHoldingUsd,
    "confetti": faStar,
    "folder": faFolder,
    "palette": faPalette,
    "survey": faPoll,
    "upload": faUpload,
    "18": faExclamationCircle,
};

const Icon = ({ type, style, className, size, ...props }) => {
    // Vérifier si le type existe dans le mapping
    const icon = iconMapping[type] || faInterrobang;

    return  <FontAwesomeIcon className={className} icon={icon} onClick={typeof props.callback === "function" ? props.callback : () => {}}/>;
};

export default Icon;

