import {createElement} from 'react';
import Can from './Can';

export function createCanBoundTo(ability) {
    return class BoundCan extends Can {
        static defaultProps = {
            ability
        }
    };
}

export function createContextualCan(Consumer) {
    return function ContextualCan(props) {
        return createElement(Consumer, null, ability => createElement(Can, {
            ability: props.ability || ability,
            action: props.action,
            subject: props.subject,
            field: props.field,
            modelName: props.modelName,
            not: props.not,
            children: props.children,
            passThrough: props.passThrough,
        }));
    };
}