import axios from 'axios';

let interval;
let currentSPAVersion;

function getCurrentSPAVersion(){
    const currentScript = document.currentScript;
    let version = false;
    if (currentScript){
        let scriptPath = currentScript.getAttribute('src');
        const match = /main\.([a-z0-9]{8})\./.exec(scriptPath);
        if (match) {
            version = match[1];
        }
        return version;
    }
    else return version;
}

currentSPAVersion = getCurrentSPAVersion();

console.log(currentSPAVersion);

function checkSPAVersionAndRefresh(spaOutdated, autoRefresh = false) {
    if(spaOutdated && spaOutdated === "outdated"){
        clearInterval(interval);
        autoRefresh ? window.location.reload(true) : document.querySelector("#spa-update").classList.add("active");
    }
}

function request(req){
    if (!!currentSPAVersion && !!process.env.REACT_SPA_VERSION_CHECK) {
        req.headers["spa-version"] = currentSPAVersion;
    }
    return req;
}

function response(res) {
    if(!!process.env.REACT_SPA_VERSION_CHECK){
        checkSPAVersionAndRefresh(res.headers['spa-version-outdated']);
    }

    return res;
}

function fetchSPAVersion(cb = (res)=>{}){
    axios.get(`${process.env.REACT_APP_BASE_API}/spa-version-check?v=${currentSPAVersion}`, {timeout:5000}).then(res=>cb(res)).catch(e=>console.log(e));
}

interval = setInterval(()=>fetchSPAVersion(res=>checkSPAVersionAndRefresh(res?.data?.status)), 60000);
fetchSPAVersion(res=>checkSPAVersionAndRefresh(res?.data?.status, true));

const SPAVersionInterceptor = {
    request,
    response
};

export default SPAVersionInterceptor;