import history from './history';
import qs from "qs";
import moment from "moment-timezone";
import _ from "lodash";

const moreThanMaxValue = (value = 0, maxValue = 99) => {
    return value > maxValue ? `${maxValue}+` : value;
}

function convertDratToText(contentRaw, length = 0) {
    let strConverted = "";
    contentRaw.blocks.forEach(block=>{
        if(block.type !== "atomic" && !!block.text) strConverted = `${strConverted}${!!strConverted ? "\n" : ""}${block.text}`;
    });
    return !!length ? _.truncate(strConverted, {length}) : strConverted;
}

const genererViews = (views = 0) => {
    if ( views < 1000 ) {
        return views;
    }
    else {
        let str = (views / 1000).toFixed(1) + 'k';
        return str.replace(/^([0-9])+/, (x) => { return separateurNumber(x); }); // Ajoute un espace tous les 3 chiffres dans la partie entière
    }
};

const separateurNumber = (nombre = 0, separateur = ' ', longueur = 3) => {
    if(nombre === null){
        nombre = 0;
    }
    /* variables */
    let string = nombre.toString(), newString;
    /* split string */
    newString = string.substr(Math.max(string.length - longueur, 0), longueur);
    // eslint-disable-next-line
    while (string = string.substr(0, string.length - longueur)) {
        newString = string.substr(Math.max(string.length - longueur, 0), longueur) + separateur + newString;
    }
    return newString;
};

function convertDateToStrDuration(date){
    let now = new moment();
    let dateMoment = moment(date);
    if(dateMoment.isValid()){
        return moment.duration(dateMoment.diff(now)).humanize(true);
    }
    else return "";
}

const classRoles = (role = '') => {

    switch (role) {
        case "Administrateur":
            role = "admin";
            break;
        case "Modérateurs":
            role = "modo";
            break;
        default :
            role = "member"
    }
    return role;
};

const redirection = (codeError = 404, link = false) => {
    switch (codeError) {
        case "404":
            history.push(link ? link : '/404');
            break;
        case "400":
            history.push(link ? link : '/');
            break;
        case "500":
            history.push(link ? link : '/');
            break;
        default :
            history.push('/');
    }

};

const trancheAge = (age, showAn = true) => {
    let tranche = '';
    if (age >= 18 && age < 25) {
        tranche = `18-25${showAn ? ' ans' : ''}`;
    }
    if (age >= 25 && age < 30) {
        tranche = `25-30${showAn ? ' ans' : ''}`;
    }
    if (age >= 30 && age < 35) {
        tranche = `30-35${showAn ? ' ans' : ''}`;
    }
    if (age >= 35 && age < 40) {
        tranche = `35-40${showAn ? ' ans' : ''}`;
    }
    if (age >= 40 && age < 45) {
        tranche = `40-45${showAn ? ' ans' : ''}`;
    }
    if (age >= 45 && age < 50) {
        tranche = `45-50${showAn ? ' ans' : ''}`;
    }
    if (age >= 50 && age < 55) {
        tranche = `50-55${showAn ? ' ans' : ''}`;
    }
    if (age >= 55 && age < 60) {
        tranche = `55-60${showAn ? ' ans' : ''}`;
    }
    if (age >= 60) {
        tranche = `60+${showAn ? ' ans' : ''}`;
    }
    return tranche;
};

const fileConvertSize = (aSize) => {
    aSize = Math.abs(parseInt(aSize, 10));
    var def = [[1, 'octets'], [1024, 'ko'], [1024 * 1024, 'Mo'], [1024 * 1024 * 1024, 'Go'], [1024 * 1024 * 1024 * 1024, 'To']];
    for (var i = 0; i < def.length; i++) {
        if (aSize < def[i][0]) return (aSize / def[i - 1][0]).toFixed(2) + ' ' + def[i - 1][1];
    }
};

const queryUrlAuthorised = {
    videos : ["period", "annee", "mois", "createurs"],
    photos : ["period", "annee", "mois", "createurs"],
    articles : ["period", "annee", "mois"],
    stories : ["categories", "byReal", "byHard"]
}

const clearCanonical = (url = "", routeName = "") =>{
    if(url.length && routeName.length && queryUrlAuthorised[routeName]){
        let authorisedQuery = queryUrlAuthorised[routeName];
        let newUrl = new URL(url);
        let getQuery = qs.parse(newUrl.search, {ignoreQueryPrefix: true});
        let params = new URLSearchParams(newUrl.search);
        if(getQuery){
            Object.keys(getQuery).forEach(key =>{
                if(!authorisedQuery.includes(key)){
                    params.delete(key);
                }
            });
        }
        params = params.toString();

        if(params.length){
            url = `${newUrl.origin}${newUrl.pathname}?${params}`
        }
        else{
            url = `${newUrl.origin}${newUrl.pathname}`
        }

    }
    else if(url.length){
        let newUrl = new URL(url);
        url = `${newUrl.origin}${newUrl.pathname}`
    }
    return url;
}

const canonical =(path, query = "", routeName = "") =>{
    let url = `${window.location.protocol}//${window.location.host}${path}`;
    if(query){
        url = `${url}${query}`;
    }
    url = clearCanonical(url, routeName);
    return url;
};

const contentRawToText =(contentRaw) =>{
    if(contentRaw && contentRaw.blocks && Array.isArray(contentRaw.blocks)){
        return contentRaw.blocks.filter(item => item.type === "unstyled" && item.type).map(item => item.text.trim()).join(' ');
    }
    else{
        return "";
    }
};
const cropText =(text = "", max = 130) =>{
    return text.length >= max ? text.substring(0, max) + "..." : text;
};


const isNoFollowMedia = (media) => {
    let {count = {comment : 0}} = media;
    if(count.comment <5){
        return true;
    }
    return false;
};

const isNoFollowAlbum = (data) => {
    let {count = {photo : 0, video : 0}} = data;
    let totalMedia = (count.photo + count.video);
    if(totalMedia <5){
        return true;
    }
    return false;
};

const isNoFollowProfil = (data) => {
    if(data){
        let isIndexed = data.isIndexed ? data.isIndexed : false;
        return !isIndexed;
    }
    else{
        return false
    }

};

const isNoFollowArticle = (data) => {
    let {count = {comment : 0}, title = "", excerpt = ""} = data;
    let titleLengthWords =  title.split(/\s+/).length;

    if(count.comment < 1 && titleLengthWords < 2 && excerpt.length < 1){
        return true;
    }
    return false;
};

const shuffleArray = (array, limit = 18, seed = false) => {
    if(!seed){
        let now = new Date();
        seed = now.getFullYear() * 1e8 + (now.getMonth()+1) * 1e6 + now.getDate() * 1e4 + now.getHours() * 1e2 + Math.floor(now.getMinutes() / 10) * 10;
    }

    let currentIndex = array.length, temporaryValue, randomIndex;
    seed = seed || 1;
    let random = function() {
        let x = Math.sin(seed++) * 10000;
        return x - Math.floor(x);
    };
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array.slice(0, limit);
}


const harmonyCatToFacette = ()=>{
    return {
        "/fellation/" : "video-amateur/fellation/",
        "/sextoy-pour-femme/" : "video-femme/gode-hot/",
        "/levrette/" : "video-amateur/levrette/",
        "/andromaque/" : "video-amateur/andromaque/",
        "/sodomie/" : "video-amateur/sodomie/",
        "/cunnilingus/" : "video-amateur/cunnilingus/",
        "/orgasme-feminin/" : "video-amateur/orgasme-feminin/",
        "/ejaculation-faciale/" : "video-amateur/ejaculation-faciale/",
        "/strip-tease/" : "video-amateur/striptease/",
        "/pieds-footjob/" : "video-femme/pieds-xxx/",
        "/insertion-sexuelle/" : "video-amateur/insertion/",
        "/black/" : "video-amateur/",
        "/femme-fontaine/" : "video-amateur/femme-fontaine/",
        "/branlette-espagnole/" : "video-amateur/branlette-espagnole/",
        "/fist-fucking/" : "video-amateur/fist-fucking/",
        "/gode-ceinture/" : "video-amateur/godes-ceinture/",
        "/anulingus/" : "video-amateur/anulingus/",
        "/rasage/" : "video-amateur/",
        "/sextoy-pour-homme/" : "video-homme/plug-hot/",
        "/dedicaces/" : "video-amateur/",
        "/enceinte/" : "video-femme/enceinte-xxx/",
        "/gangbang/" : "video-amateur/gang-bang/",
        "/en-douceur/" : "video-amateur/",
        "/charnel/" : "video-amateur/",
        "/trio-plan-a-trois/" : "video-amateur/triolisme/",
        "/partouze/" : "video-amateur/partouze/",
        "/avec-un-masque/" : "video-femme/masque-hot/",
        "/jeux-sexuels/" : "video-amateur/jeux-sexuels/",
        "/en-plein-air/" : "video-amateur/foret/",
        "/en-public/" : "video-amateur/exhibitionniste/",
        "/a-la-maison/" : "video-amateur/maison/",
        "/lesbiennes/" : "video-couple-lesbien/",
        "/preliminaires-elle-a-lui/" : "video-amateur/fellation/",
        "/preliminaires-lui-a-elle/" : "video-amateur/cunnilingus/",
        "/caresses-feminines/" : "video-amateur/masturbation-feminine/",
        "/soft/" : "video-amateur/"
    };
}

const isBot = navigator.userAgent.indexOf("prerender") > -1 || navigator.userAgent.indexOf("bot") > -1 || navigator.userAgent.indexOf("seo4ajax") > -1;

const durationVideo = duration => {
    duration = parseInt(duration);
    if(duration >= 3600){
        return '> 1 heure';
    }
    else if(duration >= 1){
        let format = duration >= 600 ? 'mm:ss' : 'm:ss';
        return moment().startOf('day').seconds(duration).format(format);
    }
    else{
        return '0:00';
    }
};

function uid(len = 8){
    len = parseInt(len);
    len = len === 0 ? 10 : len;
    return Date.now().toString(36) + Math.random().toString(36).substr(2,len);
}

function splitSlugAndId(slug){
    if(slug){
        let id = slug.split("_").pop();
        let newSlug = slug.replace(`_${id}`, '');
        let checkForHexRegExp = new RegExp("^[0-9a-fA-F]{24}$");
        if(checkForHexRegExp.test(id)){
            return {slug : newSlug, id}
        }
        return {
            id : null,
            slug : ""
        };
    }
    return {
        id : null,
        slug : ""
    };
}

const legendToTitle = (legend, maxLength = 100) => {
    let text = legend;
    // Supprimer les URLs
    text = text.replace(/https?:\/\/[^\s]+/g, '');
    // Supprimer les caractères non alphabétiques ou numériques au début
    //text = text.replace(/^[^\w]+/, '');

    const cutoffIndex = Math.min(text.indexOf('\n'), maxLength);
    const title = text.slice(0, cutoffIndex === -1 ? maxLength : cutoffIndex) || "";

    return title;
};

function insertAtRandomIndexes(arr1, arr2) {
    // Créez une copie du premier tableau
    let result = [...arr1];
  
    // Fonction pour obtenir un indice aléatoire
    function getRandomIndex(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  
    // Insérez chaque élément du deuxième tableau à un indice aléatoire
    arr2.forEach(item => {
      let index = getRandomIndex(0, result.length);
      result.splice(index, 0, item);
    });
  
    return result;
  }

function probability(probabilityPercent) {
    probabilityPercent = Math.max(0, Math.min(100, probabilityPercent));
    if (probabilityPercent === 0) return false;
    if (probabilityPercent === 100) return true;
    return Math.random() < (probabilityPercent / 100);
}

function isExternalLink(url) {
    if(!url) return {isExternal: true, path: ""};

    // Crée un objet URL pour le lien et pour la page actuelle
    const linkUrl = new URL(url, window.location.href);
    const currentUrl = new URL(window.location.href);
    // Compare le protocole, le nom d'hôte et le port
    const isExternal = linkUrl.protocol !== currentUrl.protocol ||
        linkUrl.hostname !== currentUrl.hostname ||
        linkUrl.port !== currentUrl.port;

    // Récupère le chemin (path) de l'URL
    const path = linkUrl.pathname;

    return { isExternal, path };
}

function capitalize(str) {
    if (typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
}


export {
    isBot,
    capitalize,
    genererViews,
    separateurNumber,
    classRoles,
    redirection,
    trancheAge,
    fileConvertSize,
    canonical,
    contentRawToText,
    cropText,
    isNoFollowMedia,
    isNoFollowAlbum,
    isNoFollowProfil,
    isNoFollowArticle,
    harmonyCatToFacette,
    shuffleArray,
    durationVideo,
    uid,
    splitSlugAndId,
    convertDateToStrDuration,
    legendToTitle,
    moreThanMaxValue,
    insertAtRandomIndexes,
    probability,
    isExternalLink,
    convertDratToText
}
