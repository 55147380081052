import React, { useState } from 'react';
import {Helmet} from "react-helmet";
import "./BetaAccess.scss";
import logoSrc from '../../../images/logo-full.svg';

const BetaAccess = () => {
    const [code, setCode] = useState('');
    const [error, setError] = useState(false);

    const handleCodeSubmit = (event) => {
        event.preventDefault()
        if (code === process.env.REACT_APP_BETA_ACCESS) {
            localStorage.setItem("betaAccess", code);
            setError(false);
            window.location.href = '/';
        } else {
            setError(true);
        }
    }

    const handleChange = (value)=>{
        setError(false);
        setCode(value);
    }

    return (
        <div id="beta-access">
            <Helmet>
                Accès bêta
                <meta name="prerender-status-code" content="404" />
                <meta property="robots" content="noindex" />
            </Helmet>
            <div className="beta-access-wrapper">
                <img src={logoSrc} alt="logo" />
                <h2>Accès bêta</h2>
                <p>
                    Nous sommes ravis que vous souhaitiez rejoindre notre version bêta !
                    <br/> 
                    <br/> Cette phase est cruciale pour nous car elle nous permet de peaufiner notre site avec l'aide de vos retours précieux.
                    <br/> Pour accéder à la bêta, veuillez entrer votre code d'accès bêta ci-dessous. Ce code vous a été fourni suite à votre inscription à notre liste de bêta-testeurs.
                </p>
                <form onSubmit={(e)=>handleCodeSubmit(e)}>
                    <input type="text" placeholder='Code' onChange={event=>handleChange(event.target.value)} />
                    <button type='submit' className='cta'>Go !</button>
                </form>
                {error && <div className='error'>Code invalide</div>}
                
            </div>
        </div>
    )
};

export default BetaAccess;