import bgHeader1 from './images/cover.jpg';

const coverDefault = bgHeader1;

const config = {
    preTitle: "",
    postTitle: " - Voissa",
    genderList: ['Non défini', 'Homme', 'Femme', 'Couple', 'Couple lesbien', 'Couple gay', 'Travesti'],
    genderClass: ["undefined", 'man', 'woman', 'couple', 'couple-lesbian', 'couple-gay', 'trav'],
    genderClassProfile: ['man', 'woman', 'couple', 'couple-lesbian', 'couple-gay', 'trav'],
    genderOrientation: ["Non renseigné", 'Hétéro', 'Bisexuelle', 'Homo', 'Femme Bi', 'Homme Bi'],
    orientation: ["Non renseigné", "Hétéro", "Bisexuelle", "Homo"],
    lookingFor: ["Non renseigné", "Femme hétéro", "Femme lesbienne", "Femme bi", "Homme hétéro", "Homme Gay", "Homme bi", "Couple hétéro", "Couple homo", "Couple lesbien", "Couple femme bi", "Couple homme bi", "Trav / Trans"], // efface 8 Couple bi et ajoute de  8 "Couple homo", 9 "Couple lesbien"
    tendencies: ["Non renseigné", "Fidèle", "Voyeur", "Echangiste", "Melangiste", "Cote à Cotiste", "Candauliste", "Exhib", "SM", "Gangbang"],
    mobility: ["Non renseigné", "Reçoit", "Se déplace", "Reçoit & Se déplace"],
    civilStatus: ["Non renseigné", "Célibataire", "Marié", "Couple", "Séparé", "Divorcé", "Veuf"],
    sexualPosition: ["Non renseigné", "Missionaire", "69", "Branlette espagnole", "Levrette", "Andromaque", "Autre"],
    fetishObject: ["Non renseigné", "Fouet", "Menottes", "Collants", "Masque", "Porte jartelles", "Chaussures", "Autre"],
    partnerBodyPart: ["Non renseigné", "Cheveux", "Yeux", "Bouche", "Poitrine", "Torse", "Dos", "Fesses", "Jambes", "Pieds", "Autre"],
    bodyPart: ["Non renseigné", "Cheveux", "Yeux", "Bouche", "Poitrine", "Torse", "Dos", "Fesses", "Jambes", "Pieds", "Autre"],
    placeToHaveFetishSex: ["Non renseigné", "Lit", "Salon", "Plage", "Nature", "Toilettes", "Lieux interdits", "Autre"],
    size: ["Non renseigné", "petite", "Taille moyenne", "Grande"],
    weight: ["Non renseigné", "Mince", "Poids moyen", "Kilos en trop", "Poids fort"],
    eyesColor: ["Non renseigné", "Noirs", "Marrons", "Noisettes", "Bleus", "Verts", "Gris"],
    hairColor: ["Non renseigné", "Blancs", "Poivre et sel", "Platine", "Platine", "Blonds", "Châtains", "Roux", "Bruns", "Noirs", "Couleurs vives"],
    lookStyle: ["Non renseigné", "Décontracté", "Classique", "BCBG", "Boheme", "Sportif", "Geek", "Grunge", "Gothique"],
    hairiness: ["Non renseigné", "Epilation totale", "Poilu", "Peu"],
    personality: ["Non renseigné", "Aventureux", "Conciliant", "Humoristique", "Sociable", "Insouciant", "Vif", "Expansif", "Inquiet", "Reservé", "Attentionné", "Calme", "Généreux", "Sensible", "Spontané", "Timide", "Exigeant", "Fier", "Possessif", "Solitaire", "Tenace"],
    smoker: ["Non renseigné", "Non", "Oui", "Occasionnellement"],
    role: {default: "Invité", guest: "Invité", emailUnconfirmed: "Compte non confirmé", standard: "Membre",  creator: "Voissa Créateur", moderator: "Modérateur", chatModerator: "Modérateur Chat", administrator: "Administrateur", banned: "banni", inactive: "Compte inactif"},
    warningRestriction: ["Message verbal uniquement", "Suppression de la capacité à créer du contenu", "Suspenssion", "Invisibilité des médias et interdiction de nouvelle publication"],
    contentReview : [{_id : 1, title : "Soft"}, {_id : 2, title : "Hot"}, {_id : 3, title : "Hard"}, {_id : 4, title : "Contenu sensible"}]
};

const picture = () => {
    return {
        url: process.env.REACT_APP_BASE_STATIC,
        path: '/usr/src/app/files'
    }
};

const apiCropImage = ({url = "", width = 80, height, quality = 80}) => {
    if (url) {
        return `${url}?width=${width}&height=${height}&quality=${quality}`
    }
    return "";
};

export {picture, apiCropImage, coverDefault, config};
