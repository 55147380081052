import axios from 'axios';
import actionsInterceptor from '../interceptors/actions';
import dataInterceptor from '../interceptors/data';
import tokenInterceptor from '../interceptors/token';
import sessionInterceptor from '../interceptors/sessionUid';
import SPAVersionInterceptor from '../interceptors/checkSPAVersion';

const timeout = 10000;
const baseURL = process.env.REACT_APP_BASE_API;
const apiUrl = axios.create({
    baseURL,
    timeout,
    withCredentials: true
});

apiUrl.interceptors.request.use(tokenInterceptor.request);
apiUrl.interceptors.request.use(sessionInterceptor.request);
apiUrl.interceptors.response.use(tokenInterceptor.response);
apiUrl.interceptors.response.use(actionsInterceptor.response);
apiUrl.interceptors.response.use(dataInterceptor.response, dataInterceptor.responseError);

const apiVoissa = axios.create({
    baseURL,
    timeout,
    withCredentials: true
});

apiVoissa.interceptors.request.use(tokenInterceptor.request);
apiVoissa.interceptors.request.use(sessionInterceptor.request);
apiVoissa.interceptors.response.use(tokenInterceptor.response);
apiVoissa.interceptors.request.use(SPAVersionInterceptor.request);
apiVoissa.interceptors.response.use(SPAVersionInterceptor.response);
apiVoissa.interceptors.response.use(actionsInterceptor.response);
apiVoissa.interceptors.response.use(dataInterceptor.response, dataInterceptor.responseQueryError);

const apiVoissaNoError = axios.create({
    baseURL,
    timeout,
    withCredentials: true
});

apiVoissaNoError.interceptors.request.use(tokenInterceptor.request);
apiVoissaNoError.interceptors.request.use(sessionInterceptor.request);
apiVoissaNoError.interceptors.response.use(tokenInterceptor.response);
apiVoissaNoError.interceptors.request.use(SPAVersionInterceptor.request);
apiVoissaNoError.interceptors.response.use(SPAVersionInterceptor.response);
apiVoissaNoError.interceptors.response.use(actionsInterceptor.response);
apiVoissaNoError.interceptors.response.use(dataInterceptor.response);


const getForumsStatsFromApi = () => apiUrl.get(`/forums/stats`);
const tools = {
    getLiveBottom: (data = {}) => apiUrl.get(`/tools/live-bottom`, { params:data})
}

export {
    apiUrl,
    apiVoissa,
    apiVoissaNoError,
    getForumsStatsFromApi,
    tools,
};