import React from 'react';
import './PasswordStrength.scss'; 
import classNames from 'classnames';

const VALIDATION_MAP = {
    long : "8 caractères",
    uppercase : "1 majuscule",
    lowercase : "1 minuscule",
    digit : "1 chiffre",
    special : "1 caractère spécial"
}

export function checkPasswordStrength(password = ""){
    let passwordStrength = {
        isValid : false,
        validation : {
            long : /(?=.{8,})/.test(password),
            uppercase : /(?=.*[A-Z])/.test(password),
            lowercase : /(?=.*[a-z])/.test(password),
            digit : /(?=.*[0-9])/.test(password),
            special : /([^A-Za-z0-9])/.test(password)
        }
    }

    passwordStrength.isValid = Object.values(passwordStrength.validation).every(validator=>validator);
    passwordStrength.strength = Object.values(passwordStrength.validation).filter(validator=>validator).length;
    passwordStrength.strengthLenght = Object.values(passwordStrength.validation).length;

    return passwordStrength;
}


const PasswordStrength= ({password="", className, ...props})=>{
    const passwordValidity = checkPasswordStrength(password);
    
    return  (
        <div className={classNames("password-strength", className)} {...props}>
            <div className="password-strength-bar">
                {Array.from({length: passwordValidity.strengthLenght}, (_, index) => (
                    <div key={index} className={index < passwordValidity.strength ? "password-strength-bar-item active" : "password-strength-bar-item"}/>
                ))}
            </div>
            {!passwordValidity.isValid && (
                <div className="password-strength-detail">
                    Doit contenir : {Object.keys(passwordValidity.validation).filter(key => !passwordValidity.validation[key]).map((key, index, array) => (
                        <span key={key} className="password-strength-detail-item">{VALIDATION_MAP[key]}</span>
                    ))}
                </div>
            )}
        </div>
    )
}

export default PasswordStrength;