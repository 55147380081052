import React, {lazy, Suspense} from 'react';
import { Navigate, useLocation, useParams, useRoutes } from "react-router-dom";
//import loadable from '@loadable/component';
import useToken from "./hooks/token";
import createLink from "./libs/createLink";
import { harmonyCatToFacette } from './libs/helper';
import useCurrentUser from "./queries/currentUser";
import { LoadingSuspense } from './ui/components/Loader';

//import Main from './ui/layouts/Main';
const Main = lazy(() => import('./ui/layouts/Main'));
const ItemModeration = lazy(() => import('./ui/pages/comments-moderation/components/ItemModeration'));
const TagSearch = lazy(() => import('./ui/pages/tagSearch'));
const ListReportings = lazy(() => import('./ui/pages/pc-reporting/ListReportings'));
const Reporting = lazy(() => import('./ui/pages/pc-reporting/Raporting'));
const ProfileOutletMedias = lazy(() => import('./ui/pages/profile/components/ProfileOutletMedias/ProfileOutletMedias'));
const ProfileOutletPosts = lazy(() => import('./ui/pages/profile/components/ProfileOutletPosts/ProfileOutletPosts'));
const ProfilePostsOutletArticles = lazy(() => import('./ui/pages/profile/components/ProfilePostsOutletArticles/ProfilePostsOutletArticles'));
const ProfilePostsOutletThreads = lazy(() => import('./ui/pages/profile/components/ProfilePostsOutletThreads/ProfilePostsOutletThreads'));
const ProfilePostsOutletThreadsResponses = lazy(() => import('./ui/pages/profile/components/ProfilePostsOutletThreadsResponses/ProfilePostsOutletThreadsResponses'));
const Bookmarks = lazy(() => import('./ui/pages/Bookmarks/Bookmarks'));
//const Signup = lazy(() => import('./ui/pages/Signup'));
const SignupPage = lazy(() => import('./ui/components2/Signup/Signup'));
const SigninPage = lazy(() => import('./ui/components2/Signin/Signin'));
const ForgotPassword = lazy(() => import('./ui/pages/ForgotPassword'));
// const Home = lazy(() => import('./ui/pages/home/Home'));
const Forums = lazy(() => import('./ui/pages/forums/Forums'));
const ForumsSubOrTopics = lazy(() => import('./ui/pages/forums/ForumsSubOrTopics'));
const ForumsTopic = lazy(() => import('./ui/pages/forums/ForumsTopic'));
const MediaViewer = lazy(() => import('./ui/pages/MediaViewer'));
const Articles = lazy(() => import('./ui/pages/Articles'));
// const FilActu = lazy(() => import('./ui/pages/fil-actu/FilActu'));
const Stories = lazy(() => import('./ui/pages/Stories'));
const Notifications = lazy(() => import('./ui/pages/Notifications/Notifications'));
const Settings = lazy(() => import('./ui/pages/settings/Settings'));
const GeneralSettings = lazy(() => import('./ui/pages/settings/components/SettingProfile'));
const ProfileSettings = lazy(() => import('./ui/pages/settings/components/ProfilSettings'));
const ProfileMeetSettings = lazy(() => import('./ui/pages/settings/components/ProfilMeetSettings'));
//const ProfileFriendsAndRequestSettings = lazy(() => import('./ui/pages/settings/components/FriendsAndRequest'));
const ProfilePasswordEmailSettings = lazy(() => import('./ui/pages/settings/components/PasswordEmail'));
const ProfileBlockedSettings = lazy(() => import('./ui/pages/settings/components/Blocked'));
const ProfileNotificationSettings = lazy(() => import('./ui/pages/settings/components/NotificationSettings'));
const ProfileSubscriptionSettings = lazy(() => import('./ui/pages/settings/components/Subscription'));
const Meet = lazy(() => import('./ui/pages/Meet/Meet'));
const Search = lazy(() => import('./ui/pages/search/Search'));
const Moderation = lazy(() => import('./ui/pages/comments-moderation/Moderation'));
//const ProfileUser = lazy(() => import('./ui/pages/profile/ProfileUser'));
const Charter = lazy(() => import('./ui/pages/charter/Charter'));
const Terms = lazy(() => import('./ui/pages/Terms'));
const HistoryPage = lazy(() => import('./ui/pages/History'));
const Contact = lazy(() => import('./ui/pages/contact/Contact'));
const Page404 = lazy(() => import('./ui/pages/Page404/Page404'));
const PrivacyPolicy = lazy(() => import('./ui/pages/PrivacyPolicy'));
const Explorer = lazy(() => import('./ui/pages/Explorer/Explorer'));
// const Video = lazy(() => import('./ui/pages/Video'));
const Echo = lazy(() => import('./ui/pages/Echo'));
const StoryTerms = lazy(() => import('./ui/pages/StoryTerms'));
const PcReporting = lazy(() => import('./ui/pages/pc-reporting'));
const ConfirmEmail = lazy(() => import('./ui/pages/ConfirmEmail'));
const ResetPasswordPage = lazy(() => import('./ui/pages/ResetPassword'));
const ConfirmAccount = lazy(() => import('./ui/pages/ConfirmAccount'));
const FacettesUri = lazy(() => import('./ui/pages/facettes/IndexFacetteUri'));
const Profile = lazy(() => import('./ui/pages/profile/Profile'));
//const ProfileFilActus = lazy(() => import('./ui/pages/profile/components/FilActus'));
const ProfileFilActus = lazy(() => import('./ui/pages/profile/components/ProfileOutletFeed/ProfileOutletFeed'));
const ProfileStatus = lazy(() => import('./ui/pages/profile/components/status/Status'));
const ProfileNotificationsStatus = lazy(() => import('./ui/pages/profile/components/notificationStatus/NotificationStatus'));
const ProfileInformations = lazy(() =>import('./ui/pages/profile/components/ProfileInformations'));
//const ProfileArticles = lazy(() =>import('./ui/pages/profile/components/ProfileArticles'));
const ProfileArticle = lazy(() =>import('./ui/pages/profile/components/ProfileArticle'));
const ProfileAlbums = lazy(() =>import('./ui/pages/profile/components/ProfileAlbums'));
const ProfileAlbum = lazy(() =>import('./ui/pages/profile/components/GalleryAlbum'));
const FacettesAnchor = lazy(() => import('./ui/pages/facettes/IndexFacetteAncre'));
const ConfirmSuppressionAccount = lazy(() => import('./ui/pages/ConfirmSuppressionAccount'));
const ChatWebcam = lazy(() => import('./ui/pages/ChatWebcam'));
const ForumLastTopics = lazy(() => import('./ui/pages/forums/ForumLastTopics'));
const ComparatorDating = lazy(() => import('./ui/pages/ComparatorDating'));
const TagModerations = lazy(() => import('./ui/pages/tagModerations/TagModerations'));
const TagModerationsAskForAuthorization = lazy(() => import('./ui/pages/tagModerations/AskForAuthorization/AskForAuthorization'));
const TagModerationsQualifications = lazy(() => import('./ui/pages/tagModerations/Qualifications/Qualifications'));
const TagModerationsQualificationPhoto = lazy(() =>import('./ui/pages/tagModerations/QualificationPhoto/QualificationPhoto'));
const TagModerationsQualificationVideo = lazy(() => import('./ui/pages/tagModerations/QualificationVideo/QualificationVideo'));
const TagModerationsVerifications = lazy(() => import('./ui/pages/tagModerations/Verifications/Verifications'));
const TagModerationsVerificationPhoto = lazy(() => import('./ui/pages/tagModerations/VerificationsPhoto/VerificationsCategory'));
const TagModerationsVerificationVideo = lazy(() => import('./ui/pages/tagModerations/VerificationsVideo/VerificationsCategory'));
const TagModerationsTreatmentVideo = lazy(() => import('./ui/pages/tagModerations/TreatmentVideo/TreatmentVideo'));
const ChatWebcamBanned = lazy(() => import('./ui/pages/chatWebcamBanned'));
const ChangeWeakPassword = lazy(() => import('./ui/pages/ChangeWeakPassword'));
const ComparatorWebcam = lazy(() => import('./ui/pages/compatatorWebcam/CompatatorWebcam'));
const ParentalControl = lazy(() => import('./ui/pages/ParentalControl/ParentalControl'));
const TrustSecurity = lazy(() => import('./ui/pages/trustSecurity/TrustSecurity'));
const HomeFeed = lazy(() => import('./ui/pages/home-feed/HomeFeed'));

/*
const MessengerRouterDesktop = lazy(() => import('./ui/pages/messenger/MessengerRouterDesktop'));
const MessengerRouterMobile = lazy(() => import('./ui/pages/messenger/MessengerRouterMobile'));
const MessengerNoRoomSelected = lazy(() => import('./ui/pages/messenger/NoRoomSelected'));
const MessengerRoomCreate = lazy(() => import('./ui/pages/messenger/RoomCreate'));
const MessengerRoomSelected = lazy(() => import('./ui/pages/messenger/Room'));
const MessengerMobileRoomInfo = lazy(() => import('./ui/pages/messenger/MessengerMobile/MessengerMobileRoomInfo'));
*/

const MediasSfw = lazy(() => import('./ui/pages/tagModerations/MediasSfw/MediasSfw'));

const SubscriptionPage = lazy(() => import('./ui/components2/Subscription/Subscription'));
const Relations = lazy(() => import ("./ui/components2/Relations/Relations"));
const Friends = lazy(() => import ("./ui/components2/Relations/components/Friends/Friends"));
const Followers = lazy(() => import ("./ui/components2/Relations/components/Followers/Followers"));
const Followings = lazy(() => import ("./ui/components2/Relations/components/Followings/Followings"));
const MemberCircle = lazy(() => import ("./ui/components2/Relations/components/MemberCircle/MemberCircle"));
const MenuPage = lazy(() => import('./ui/pages/Menu/Menu'));
const VMessengerPage = lazy(() => import('./ui/pages/VMessenger/VMessenger'));


function RedirectWithStatus({to = ""}) {
    const params = useParams();
    let uri = "/404"
    if(params.params){
        uri = `${to}/${params.params}${params.page ? `/${params.page}` : ""}`
    }
    return <Navigate to={uri} replace />;
}

function RedirectVideoCategories() {
    const params = useParams();
    let currentCategory = `/${params.tagSlug || ""}`;
    if(currentCategory && currentCategory !== "" && !currentCategory.endsWith("/")){
        currentCategory = `${currentCategory}/`;
    }
    let categories = harmonyCatToFacette();
    let to = categories[currentCategory] ? createLink('facette', {slug : categories[currentCategory]}) : "/404";
    return <Navigate to={to} replace />;
}

function InterceptFacetteUri() {
    const location = useLocation();

    let currentUrl = location.pathname;
    currentUrl = currentUrl.replace(createLink('facette'), '');

    let currentUrlSplit = currentUrl.split('/page/');
    let page = currentUrlSplit[1] || 1;
    currentUrl = currentUrlSplit[0] || "/";
    page = parseInt(page);

    if(!currentUrl.endsWith("/")){
        currentUrl = `${currentUrl}/`;
    }

    return <FacettesUri page={page} categorySlug={currentUrl} />
}

function RedirectToPageOne() {
    const location = useLocation();
    let uri = location.pathname.replace('page', '');
    if(uri.endsWith('/')){
        uri = uri.slice(0, -1);
    }
    if(uri.endsWith('/')){
        uri = uri.slice(0, -1);
    }
    if(uri.endsWith('/all')){
        uri = `${uri}/page/1`
    }
    uri = `${uri}${location.search}${location.hash}`;
    
   return <Navigate to={uri} replace />
}

function RedirectToNoTopic() {
    const location = useLocation();
    let uri = location.pathname.replace('topic', '');
    if(uri.endsWith('/')){
        uri = uri.slice(0, -1);
    }
    if(uri.endsWith('/')){
        uri = uri.slice(0, -1);
    }
    uri = `${uri}${location.search}${location.hash}`;
    return <Navigate to={uri} replace />
}


const ProtectedRoutePrivate = ({
        redirectPath = '/connexion',
        children,
    }) => {
    const [token] = useToken();

    const {isSuccess : isSuccessCurrentUser, isLoading : isLoadingCurrentUser} = useCurrentUser({enabled : !!token});

    if ((!isSuccessCurrentUser && !isLoadingCurrentUser) || !token) {
        return <Navigate to={redirectPath} replace />;
    }
    else if(isSuccessCurrentUser){
        return <Suspense fallback={<LoadingSuspense />}>{children}</Suspense>
    }
    else{
        return (<LoadingSuspense/>)
    }
};

function MainRouter() {
    //const breakpoints = useBreakpoint();
/*
    let routesMessenger = breakpoints.md ? {
        path: `messenger`,
        element:  <ProtectedRoutePrivate><MessengerRouterMobile /></ProtectedRoutePrivate>,
        children: [
            {path: "", element: <MessengerRouterDesktop isMobile={true} />},
            {path: "new", element: <MessengerRoomCreate isMobile={true} />},
            {path: ":roomId", element: <MessengerRoomSelected isMobile={true} />},
            {path: ":roomId/infos", element: <MessengerMobileRoomInfo />},

        ]
    } : {
        path: `messenger`,
        element:  <ProtectedRoutePrivate><MessengerRouterDesktop /></ProtectedRoutePrivate>,
        children: [
            {path: "", element: <MessengerNoRoomSelected />},
            {path: "new", element: <MessengerRoomCreate />},
            {path: ":roomId", element: <MessengerRoomSelected />},
        ]
    };*/


    return useRoutes([
        // { path: "/maintenance", element: <Maintenance /> },
        { path: "/echo", element: <Echo /> },
        { path: "/chat-webcams/banned", element: <ProtectedRoutePrivate><ChatWebcamBanned /></ProtectedRoutePrivate> },
        { path: "/v-messenger/:userId?", element: <VMessengerPage />},
        {
            path: "/",
            element: <Main />,
            children: [
                { 
                    path: "", 
                    element: <HomeFeed />
                },
                { path: "paiement", element: <SubscriptionPage /> },
                { path: "paiement/confirme", element: <SubscriptionPage display={"success"} /> },
                { path: "paiement/erreur", element: <SubscriptionPage display={"error"} /> },
                { path: "paiement/en-attente", element: <SubscriptionPage display={"processing"} /> },
                { path: "inscription", element: <SignupPage /> },
                { path: "menu", element: <MenuPage /> },
                { path: "connexion", element: <SigninPage /> },
                { path: "forgot-password", element: <ForgotPassword /> },
                { path: "change-password", element: <ChangeWeakPassword /> },
                { path: "webcams", element: <ComparatorWebcam /> },
                { path: "comparateur/rencontres", element: <ComparatorDating /> },
                { path: "chat-webcams", element: <ProtectedRoutePrivate><ChatWebcam /></ProtectedRoutePrivate> },
                { path: "email-verification", element: <ConfirmEmail /> },
                { path: "verification-compte", element: <ConfirmAccount /> },
                { path: "nouveau-mot-de-passe", element: <ResetPasswordPage /> },
                { path: "blog-sexe-amateur", element: <Articles /> },
                { path: "blog-sexe-amateur/page", element: <RedirectToPageOne /> },
                { path: "blog-sexe-amateur/page/:page", element: <Articles /> },
                { path: "xxx-photos/:paramId", element: <MediaViewer /> },
                { path: "xxx-videos/:paramId", element: <MediaViewer /> },
                { path: "photos-sexe/:page?", element: <Explorer kind="photo" /> },
                { path: "videos-sexe/:page?", element: <Explorer kind="video" /> },
                { path: "histoires-de-sexe/regles", element: <StoryTerms /> },
                { path: "histoires-de-sexe/:page?", element: <Stories /> },
                { path: "rencontre/:page?", element: <Meet /> },

                {
                    path: "pc-moderateur",
                    element: <PcReporting />,
                    children: [
                        { path: "", element: <ListReportings /> },
                        { path: "page", element: <RedirectToPageOne /> },
                        { path: "page/:page", element: <ListReportings /> },
                        { path: ":reportId/:page?", element: <Reporting /> }
                    ]
                },
                {
                    path: "mes-parametres",
                    element: <ProtectedRoutePrivate><Settings /></ProtectedRoutePrivate>,
                    children: [
                        {path: "", element: <ProfileSettings />},
                        {path: "profil", element: <GeneralSettings />},
                        {path: "rencontre", element: <ProfileMeetSettings />},
                        //{path: "amis-et-demandes", element: <ProfileFriendsAndRequestSettings />},
                        {path: "email-mdp", element: <ProfilePasswordEmailSettings />},
                        {path: "blocage", element: <ProfileBlockedSettings />},
                        {path: "options-notifications", element: <ProfileNotificationSettings />},
                        {path: "abonnement", element: <ProfileSubscriptionSettings />}
                    ]
                },
                {/*path : "mon-profil/relations",
                    element: <ProtectedRoutePrivate><Relations isMe={true} /></ProtectedRoutePrivate>,
                    children: [
                        {path: "", element: <Friends />},
                        {path: "followers", element: <Followers />},
                        {path: "followings", element: <Followings />},
                        {path: "circle", element: <MemberCircle />}
                    ]
                */},
                {/*
                    path: "mon-profil",
                    element: <ProtectedRoutePrivate><Profile isMe={true} /></ProtectedRoutePrivate>,
                    children: [
                        {path: "fil-actualite", element: <ProfileFilActus />},
                        {path: "status/:statusId", element: <ProfileStatus />},
                        {path: "notification/:notificationId", element: <ProfileNotificationsStatus />},
                        {path: "rencontre", element: <ProfileInformations />},
                        {path: "articles", element: <ProfileArticles /> },
                        {path: "articles/page", element: <RedirectToPageOne /> },
                        {path: "articles/page/:page", element: <ProfileArticles /> },
                        {path: "articles/:paramId", element: <ProfileArticle /> },
                        {path: "collections", element: <ProfileAlbums />},
                        {path: "collections/page", element: <RedirectToPageOne />},
                        {path: "collections/page/:page", element: <ProfileAlbums />},
                        {path: "collections/:paramId", element: <ProfileAlbum />},
                        {path: "collections/:paramId/page", element: <RedirectToPageOne />},
                        {path: "collections/:paramId/page/:page", element: <ProfileAlbum />},
                        {path: ":page?", element: <ProfileOutletMedias />}
                    ]
                */},
                {path: "mes-favoris", element: <Bookmarks />},
                {path : "profils/:userSlug/relations",
                    element: <Relations />,
                    children: [
                        {path: "", element: <Friends />},
                        {path: "followers", element: <Followers />},
                        {path: "followings", element: <Followings />},
                        {path: "circle", element: <MemberCircle />}
                    ]
                },
                {
                    path: "profils/:userSlug",
                    element: <Profile />,
                    children: [
                        {path: "", element: <ProfileFilActus />},
                        {path: "status/:statusId", element: <ProfileStatus />},
                        {path: "notification/:notificationId", element: <ProfileNotificationsStatus />},
                        {path: "rencontre", element: <ProfileInformations />},
                        /*{path: "articles", element: <ProfileArticles /> },
                        {path: "articles/page", element: <RedirectToPageOne /> },
                        {path: "articles/page/:page", element: <ProfileArticles /> },
                        {path: "articles/:paramId", element: <ProfileArticle /> },*/
                        {path: "collections/:paramId", element: <ProfileAlbum />},
                        {path: "collections/:paramId/page", element: <RedirectToPageOne />},
                        {path: "collections/:paramId/page/:page", element: <ProfileAlbum />},
                        {path: "collections", element: <ProfileAlbums />},
                        {path: "collections/page", element: <RedirectToPageOne />},
                        {path: "collections/page/:page", element: <ProfileAlbums />},
                        {path: "medias", element: <ProfileOutletMedias />},
                        {
                            path: "posts",
                            element: <ProfileOutletPosts />,
                            children: [
                                {path: "threads", element: <ProfilePostsOutletThreads />},
                                {path: "reponses", element: <ProfilePostsOutletThreadsResponses />},
                            ]
                        }
                    ]
                },
                {
                    path: "blog-sexe-amateur/:userSlug",
                    element: <Profile />,
                    children: [
                        {
                            path: "",
                            element: <ProfileOutletPosts />,
                            children: [
                                {path: "", element: <ProfilePostsOutletArticles /> },
                                {path: "page", element: <RedirectToPageOne /> },
                                {path: "page/:page", element: <ProfilePostsOutletArticles /> },
                                {path: ":paramId", element: <ProfileArticle /> },
                            ]
                        },
                        //{path: "", element: <ProfileArticles /> },
                        /*{path: "page", element: <RedirectToPageOne /> },
                        {path: "page/:page", element: <ProfileArticles /> },
                        {path: ":paramId", element: <ProfileArticle /> },*/
                    ]
                },
                {
                    path: "tag-moderations",
                    element: <ProtectedRoutePrivate><TagModerations /></ProtectedRoutePrivate>,
                    children: [
                        {path: "", element: <TagModerationsAskForAuthorization /> },
                        {path: "qualifications", element: <TagModerationsQualifications /> },
                        {path: "qualifications-photo/:categoryId", element: <TagModerationsQualificationPhoto /> },
                        {path: "qualifications-video/:categoryId", element: <TagModerationsQualificationVideo /> },
                        {path: "verifications", element: <TagModerationsVerifications /> },
                        {path: "verifications-photo/:categoryId", element: <TagModerationsVerificationPhoto /> },
                        {path: "verifications-video/:categoryId", element: <TagModerationsVerificationVideo /> },
                        {path: "traitements-video/:mediaId", element: <TagModerationsTreatmentVideo /> },
                        {path: "traitements-video", element: <TagModerationsTreatmentVideo /> },
                        {path: "medias-sfw", element: <MediasSfw /> },
                    ]
                },
                // { path: "fil-actualite", element: <ProtectedRoutePrivate><FilActu /></ProtectedRoutePrivate>},
                { path: "notifications", element: <ProtectedRoutePrivate><Notifications /></ProtectedRoutePrivate>},
                { path: "supprimer-mon-compte", element: <ProtectedRoutePrivate><ConfirmSuppressionAccount /></ProtectedRoutePrivate>},
                { path: "recherche", element: <ProtectedRoutePrivate><Search /></ProtectedRoutePrivate>},
                {/* path: "recherche/:searchUsername/:page?", element: <Search />*/},
                {
                    path: "moderation-commentaire",
                    element: <ProtectedRoutePrivate><Moderation /></ProtectedRoutePrivate>,
                    children: [
                        {path: "articles/page?", element: <ItemModeration parentKind={"articles"} /> },
                        {path: ":page?", element: <ItemModeration parentKind={"medias"} /> },
                    ]
                },

                //STAR TAGSEARCH
                { path: "photo-sexe/:tagSlug", element: <TagSearch kind={"gallery"} /> },
                { path: "photo-sexe/:tagSlug/page", element: <RedirectToPageOne /> },
                { path: "photo-sexe/:tagSlug/page/:page", element: <TagSearch kind={"gallery"} /> },
                { path: "photo-sexe/:tagSlug/:userSlug", element: <TagSearch kind={"gallery"} /> },
                { path: "photo-sexe/:tagSlug/:userSlug/page", element: <RedirectToPageOne /> },
                { path: "photo-sexe/:tagSlug/:userSlug/page/:page", element: <TagSearch kind={"gallery"} /> },
                { path: "photo-sexe/:tagSlug/:userSlug/all", element: <RedirectToPageOne /> },
                { path: "photo-sexe/:tagSlug/:userSlug/:all/page", element: <RedirectToPageOne /> },
                { path: "photo-sexe/:tagSlug/:userSlug/:all/page/:page", element: <TagSearch kind={"gallery"} /> },


                { path: "video-sexe/:tagSlug", element: <TagSearch kind={"video"} /> },
                { path: "video-sexe/:tagSlug/page", element: <RedirectToPageOne /> },
                { path: "video-sexe/:tagSlug/page/:page", element: <TagSearch kind={"video"} /> },
                { path: "video-sexe/:tagSlug/:userSlug", element: <TagSearch kind={"video"} /> },
                { path: "video-sexe/:tagSlug/:userSlug/page", element: <RedirectToPageOne /> },
                { path: "video-sexe/:tagSlug/:userSlug/page/:page", element: <TagSearch kind={"video"} /> },
                { path: "video-sexe/:tagSlug/:userSlug/all", element: <RedirectToPageOne /> },
                { path: "video-sexe/:tagSlug/:userSlug/:all/page", element: <RedirectToPageOne /> },
                { path: "video-sexe/:tagSlug/:userSlug/:all/page/:page", element: <TagSearch kind={"video"} /> },

                { path: "histoire-de-sexe/:tagSlug", element: <TagSearch kind={"story"} /> },
                { path: "histoire-de-sexe/:tagSlug/page", element: <RedirectToPageOne /> },
                { path: "histoire-de-sexe/:tagSlug/page/:page", element: <TagSearch kind={"story"} /> },
                { path: "histoire-de-sexe/:tagSlug/:userSlug", element: <TagSearch kind={"story"} /> },
                { path: "histoire-de-sexe/:tagSlug/:userSlug/page", element: <RedirectToPageOne /> },
                { path: "histoire-de-sexe/:tagSlug/:userSlug/page/:page", element: <TagSearch kind={"story"} /> },
                { path: "histoire-de-sexe/:tagSlug/:userSlug/all", element: <RedirectToPageOne /> },
                { path: "histoire-de-sexe/:tagSlug/:userSlug/:all/page", element: <RedirectToPageOne /> },
                { path: "histoire-de-sexe/:tagSlug/:userSlug/:all/page/:page", element: <TagSearch kind={"story"} /> },

                { path: "article-sexe/:tagSlug", element: <TagSearch kind={"article"} /> },
                { path: "article-sexe/:tagSlug/page", element: <RedirectToPageOne /> },
                { path: "article-sexe/:tagSlug/page/:page", element: <TagSearch kind={"article"} /> },
                { path: "article-sexe/:tagSlug/:userSlug", element: <TagSearch kind={"article"} /> },
                { path: "article-sexe/:tagSlug/:userSlug/page", element: <RedirectToPageOne /> },
                { path: "article-sexe/:tagSlug/:userSlug/page/:page", element: <TagSearch kind={"article"} /> },
                { path: "article-sexe/:tagSlug/:userSlug/all", element: <RedirectToPageOne /> },
                { path: "article-sexe/:tagSlug/:userSlug/:all/page", element: <RedirectToPageOne /> },
                { path: "article-sexe/:tagSlug/:userSlug/:all/page/:page", element: <TagSearch kind={"article"} /> },
                //END TAGSEARCH

                //START FORUM
                { path: "forum-sex", element: <ForumLastTopics /> },
                { path: "forum-sex/categories", element: <Forums /> },
                { path: "forum-sex/:slugParent/:slug/topic", element: <RedirectToNoTopic /> },
                { path: "forum-sex/:slugParent/:slug/topic/:paramTopic/:page?", element: <ForumsTopic /> },
                { path: "forum-sex/:slug/topic", element: <RedirectToNoTopic /> }, // conflit
                { path: "forum-sex/:slug/topic/:paramTopic/:page?", element: <ForumsTopic /> },
                { path: "forum-sex/:slugParent/:slug/:page?", element: <ForumsSubOrTopics /> },
                { path: "forum-sex/:slug/:page?", element: <ForumsSubOrTopics /> },
                
                //END FORUM

                //STAR REDIRECTION V1
                { path: "videos/:tagSlug/page/:page", element: <RedirectVideoCategories /> },
                { path: "videos/:tagSlug", element: <RedirectVideoCategories /> },

                { path: "videos", element: <Navigate to={"/videos-sexe"} /> },
                { path: "photos/hier", element: <Navigate to={"/photos-sexe"} /> },
                { path: "photos/semaine", element: <Navigate to={"/photos-sexe"} /> },
                { path: "photos/mois", element: <Navigate to={"/photos-sexe"} /> },
                { path: "photos/mois-dernier", element: <Navigate to={"/photos-sexe"} /> },

                { path: "blogs/hier", element: <Navigate to={"/blog-sexe-amateur"} /> },
                { path: "blogs/semaine", element: <Navigate to={"/blog-sexe-amateur"} /> },
                { path: "blogs/mois", element: <Navigate to={"/blog-sexe-amateur"} /> },
                { path: "blogs/mois-dernier", element: <Navigate to={"/blog-sexe-amateur"} /> },

                { path: "histoires/:params/page/:page", element: <RedirectWithStatus to={"/histoire-de-sexe"} /> },
                { path: "histoires/:params", element: <RedirectWithStatus to={"/histoire-de-sexe"} /> },
                { path: "histoires", element: <Navigate to={"/histoire-de-sexe"} /> },
                //END REDIRECTION V1

                { path: "charte", element: <Charter /> },
                { path: "cgus", element: <Terms /> },
                { path: "historique", element: <HistoryPage /> },
                { path: "contact", element: <Contact /> },
                { path: "privacypolicy", element: <PrivacyPolicy /> },
                { path: "controle-parental", element: <ParentalControl /> },
                { path: "confiance-et-securite", element: <TrustSecurity /> },
                { path: "page-introuvable", element: <Page404 /> },
                { path: "f", element: <FacettesAnchor />},
                { path: "f/page", element: <RedirectToPageOne />},
                { path: "f/page/:page", element: <FacettesAnchor />},
                { path: "f/*/page", element: <RedirectToPageOne />},
                { path: "f/*/page/:page", element: <InterceptFacetteUri />},
                { path: "f/*", element: <InterceptFacetteUri />},
                { path: "*", element: <Page404 /> }
            ]
        }
    ]);
}

export default MainRouter;
