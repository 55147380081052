import React, {Fragment, useRef, useState} from 'react';
import moment from "moment";
import {Button, ButtonGroup} from "../Button";
import {DateInputField, NumberInputField} from "../FormField";
import ListTypeMessages from "./ListTypeMessages";
import WarningEditor from "./WarningEditor/WarningEditor";
import convertToNext from "../../../libs/lexical/convertissors/simple";
import {useQuery} from "@tanstack/react-query";
import {
    fetchTagsWarning,
    TAGS_WARNING_KEY
} from "../../../api/public-voissa/tags";


const startDate = new Date();
const endDate = moment(startDate).add("1", 'days');

const initialWarning = {
    warningReason: '',
    memberNote : {},
    moderatorNote : {},
    tag : "",
    restriction: {
        type: 0,
        isPermanent: false,
        startedAt: startDate,
        endedAt: endDate
    }
};

const WarningCreate = ({
        username = "",
        handleClose = () =>{},
        createUserWarning = () =>{}
    }) =>{

    let firstEditorRef = useRef();

    const [currentWarning, setCurrentWarning] = useState(initialWarning);
    const [restrictionDuration, setRestrictionDuration] = useState(1);
    const [hoursToAdd, setHoursToAdd] = useState(1);

    const {data : dataTags} = useQuery(
        [TAGS_WARNING_KEY],
        ()=> fetchTagsWarning(),
        {
            staleTime : (1000 * 60 * 5),
            placeholderData : {
                tags : []
            },
            select : (data) =>{
                return {
                    tags : data.data,
                }
            }
        }
    );

    let {tags = []} = dataTags || {};


    function handleRestriction(key, value){
        setCurrentWarning({...currentWarning, restriction: {...currentWarning.restriction, [key] : value}});
    }

    function handleHourRestriction(value){
        let truncValue = Math.trunc(value);
        if(truncValue >= 23){
            truncValue = 23
        }
        if(truncValue < 1){
            truncValue = null
        }
        let startDate = new Date();
        let endDate = moment(startDate).add(truncValue, 'hours');
        setHoursToAdd(truncValue);
        setCurrentWarning({...currentWarning, restriction: {...currentWarning.restriction, endedAt : endDate}});
    }

    function handleRestrictionDuration(key, value){
        setCurrentWarning({...currentWarning, restriction: {...currentWarning.restriction, isPermanent : key === "isPermanant" ? value : false}});
        setRestrictionDuration(key === "isPermanant" ? 0 : value);
        setHoursToAdd(1);
    }

    function handleSubmit() {
        if(currentWarning.tag){
            let {text : note} = convertToNext(currentWarning.memberNote);
            let {text : moderatorNote} = convertToNext(currentWarning.moderatorNote);
            createUserWarning({
                ...currentWarning,
                note,
                moderatorNote
            });
            handleClose();
        }
    }

    function _onSelectText(text){
        if (firstEditorRef.current) firstEditorRef.current.insert(text);
    }

    return (
        <div id="warnUser" className="modal-container">
            <div className="modal-topline">Avertir <b>{username}</b></div>
            <div className={"modal-body column"}>
                <div className="column my-8">
                    <div className="text label bold pb-4">Message Type</div>
                    <ListTypeMessages onSelect={_onSelectText.bind(this)}  />
                </div>
                <div className="column my-8">
                    <div className="text label bold pb-4">Note au membre</div>
                    <WarningEditor onChange={(editorState)=>setCurrentWarning({...currentWarning, memberNote: editorState})} ref={firstEditorRef}/>
                </div>
                <div className="column my-8">
                    <div className="text label bold pb-4">Note pour les modérateurs</div>
                    <WarningEditor onChange={(editorState)=>setCurrentWarning({...currentWarning, moderatorNote: editorState})}/>
                </div>
                <div className="row">
                    <div className="column my-8 full">
                        <div className="text label bold pb-4">Raison</div>
                        <select name="warningReason" value={currentWarning.tag}
                                onChange={(event) => setCurrentWarning({...currentWarning, "tag" : event.target.value})}>
                            <option value="">--Sélectionner une raison--</option>
                            {tags.map(item => <option key={item._id} value={item._id}>{item.title}</option>)}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="column my-8 full">
                        <div className="text label bold pb-4">Punition</div>
                        <select name="restriction"
                                value={currentWarning.restriction.type}
                                onChange={(event) => handleRestriction("type", event.target.value)}
                        >
                            <option value="0">Message verbal uniquement</option>
                            <option value="1">Supprimer la capacité à créer du contenu</option>
                            <option value="3">Invisibilité des publications et interdiction de nouvelle publication</option>
                        </select>
                    </div>
                </div>
                {(currentWarning.restriction.type === 1 || currentWarning.restriction.type === "1") ? (
                    <div className={"column punishment-section"}>
                        <div className="text label bold pb-4">Durée de la sanction</div>
                        <ButtonGroup>
                            <Button onClick={()=>handleRestrictionDuration("isPermanant", true)}
                                    active={restrictionDuration === 0}
                                    content={"Permanente"} />
                            <Button onClick={()=>handleRestrictionDuration("restrictionDuration", 1)}
                                    active={restrictionDuration === 1}
                                    content={"Jours"} />
                            <Button onClick={()=>handleRestrictionDuration("restrictionDuration", 2)}
                                    active={restrictionDuration === 2}
                                    content={"Heures"} />
                        </ButtonGroup>
                        {!currentWarning.restriction.isPermanent && (
                            <Fragment>
                                {(restrictionDuration === 1 || restrictionDuration === "1") && (
                                    <div className={"restriction-duration"}>
                                        Du&nbsp;
                                        <DateInputField value={currentWarning.restriction.startedAt}
                                                        format={"DD/MM/YYYY"}
                                                        nameOfValueToUpdate={"startedAt"}
                                                        convertToFormat={"YYYY-MM-DD"}
                                                        showTime={false}
                                                        handleValue={handleRestriction}/>&nbsp;
                                        au&nbsp;
                                        <DateInputField value={currentWarning.restriction.endedAt}
                                                        format={"DD/MM/YYYY"}
                                                        nameOfValueToUpdate={"endedAt"}
                                                        convertToFormat={"YYYY-MM-DD"}
                                                        showTime={false}
                                                        handleValue={handleRestriction}/>&nbsp;
                                    </div>
                                )}
                                {(restrictionDuration === 2 || restrictionDuration === "2") && (
                                    <div className={"restriction-duration"}>
                                        <NumberInputField onChange={(event)=>handleHourRestriction(event.target.value)}
                                                          min={1}
                                                          max={23}
                                                          type="number"
                                                          step={"1"}
                                                          value={hoursToAdd} />&nbsp;
                                        heures
                                    </div>
                                )}
                            </Fragment>
                        )}
                    </div>
                ) : null}
            </div>
            <div className="modal-baseline">
                <Button size={"small"}
                        btnStyle={"grey"}
                        onClick={() => handleClose()}
                        content={"Annuler"}/>
                <Button size={"small"}
                        btnStyle={"primary-reverse"}
                        disabled={!currentWarning.tag}
                        onClick={() => handleSubmit()}
                        content={"Envoyer"}/>
            </div>
        </div>
    )
};

export default WarningCreate;