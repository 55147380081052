import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


const PageLoader = (props) => {
    return (
        <div className={`loader spin-loader big ${props.className || ""}`} />
    )
};

const SpinLoader = (props) => {
    return (
        <div className={`loader spin-loader ${props.className || ""}`} />
    )
};

const OutletLoader = (props) => {
    return (
       <div className="outlet-loader">
         <div className={`loader spin-loader ${props.className || ""}`} />
       </div>
    )
};

const SpinLoaderPercentage = (props) => {
    let {percentage = 0} = props;
    return (
        <div className={`spin-loader-percentage`}>
            <CircularProgressbar value={percentage} text={`${percentage}%`} />
        </div>
    )
};

const LoadingBar = (props) => {
    return(
        <div className={"infinite-loading-container"}>
            <div className={`infinite-loading-bar ${props.className || ""}`} />
        </div>
    )
}

const LoadingSuspense = () =>{
    return <div className={"loading-suspense"}><SpinLoader /></div>
}

export {PageLoader, SpinLoader, LoadingBar, SpinLoaderPercentage, LoadingSuspense, OutletLoader};
