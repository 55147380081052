import React, { createContext, useContext, useState } from 'react';

const FiltersLocalContext = createContext();

export const FiltersLocalProvider = ({ children }) => {
  const [filters, setFilters] = useState({});

  const setFilter = (label, value) => {
    setFilters(prevFilters => {
      return { ...prevFilters, [label]: value };
    });
  };

  const getFilter = (label, defaultValue) => {
    return filters[label] === undefined ? defaultValue : filters[label];
  }

  return (
    <FiltersLocalContext.Provider value={{ filters, setFilter, getFilter, setFilters }}>
      {children}
    </FiltersLocalContext.Provider>
  );
};

export const useFiltersLocal = (defaultFilters = {}) => {
  const { filters, getFilter, setFilter, setFilters } = useContext(FiltersLocalContext);
  const filtersWithDefault = {...defaultFilters,...filters};

  return { filters : filtersWithDefault, getFilter, setFilter, setFilters };
};