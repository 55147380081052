import React from 'react';
import { Link } from 'react-router-dom';
import './Button.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

export const BUTTON_VARIANTS = {
    REVERSE: 'button-reverse',
    DARKEN: 'button-darken',
    CLEAR: 'button-clear',
    OUTLINE: 'button-outline',
    CTA: 'button-cta',
    CTA_LIGHT: 'button-cta-light',
    PINK_GRADIENT: 'button-pink-gradient',
    // primary: 'button-primary',
    // secondary: 'button-secondary',
    // tertiary: 'button-tertiary',
    // danger: 'button-danger',
    // link: 'button-link',
    // icon: 'button-icon',
};

const Button = ({ 
    children, 
    onClick,
    isLink, 
    isExternal,
    active, 
    disabled,
    loading,
    className,
    badge,
    variant,
    onlyIcon,
    small,
    big,
    full,
    ...props 
}) => {
    const LinkTag = isExternal ? 'a' : Link;
    const Tag = isLink ? LinkTag : 'button';
    
    const handleClick = (e) => {
        if (loading || disabled) {
            e.preventDefault();
            return;
        }
        if (onClick) {
            onClick(e);
        }
    }
    return (
        <Tag className={classNames('button-v3', className, variant, {active, disabled, onlyIcon, small,big, full})} {...props} onClick={handleClick}>
            {loading && <div className="button-loading">
                <FontAwesomeIcon className="button-loading-icon" icon={faSpinnerThird} spin />    
            </div>}
            {children}
            {badge && <span className="button-badge">{badge}</span>}
        </Tag>
    );
};

export default Button;
