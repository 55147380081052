import React, { createContext, useState, useContext } from 'react';

export const DebugContext = createContext();

export const DebugProvider = ({ children }) => {
  const [toggle, setToggle] = useState(false);

  // Exposer la fonction de modification à l'objet global window
  window.activateDebug = () => {
    setToggle(true);
    return 'debug activated';
  };

  return (
    <DebugContext.Provider value={toggle}>
      {children}
    </DebugContext.Provider>
  );
};

export const Debug = ({ children }) => {
    const toggled = useContext(DebugContext);
    return toggled ? children : null;
  };