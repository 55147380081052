export const errorSkeleton = {
    errors: {},
    message: null,
    name: null,
    statusCode: null,
    error: null
};

export function normalizeError({
                                   errors = {},
                                   message = null,
                                   name = null,
                                   statusCode = null,
                                   error = null
                               }) {
    return {
        errors,
        message,
        name,
        statusCode,
        error,
    }
}