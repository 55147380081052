import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShare} from "@fortawesome/free-solid-svg-icons";
import {SelectField} from "../FormField";
import _ from "lodash";
import {useQuery} from "@tanstack/react-query";
import {fetchMessagesType, WARNING_MESSAGES_TYPE_KEY} from "../../../api/public-voissa/warnings";


export const ListTypeMessages = ({
        onSelect =  (text)=>{}
     }) => {

    const [currentOption, setCurrentOptions] = useState("");

    const {data : dataMessagesType} = useQuery(
        [WARNING_MESSAGES_TYPE_KEY],
        ()=> fetchMessagesType(),
        {
            staleTime : (1000 * 60 * 5)
        }
    );

    let messagesType = dataMessagesType || [];

    function _onSelect() {
        if(currentOption === ""){
            onSelect({});
        }
        else{
            let selectOption = messagesType.find(item => item._id === currentOption);
            if(_.get(selectOption, 'message')){
                onSelect(selectOption.message);
            }
        }
    }

    return (
        <div id="list-type-messages">
            <div className={"options-messages"}>
                <SelectField name={"type-message"} value={currentOption}
                             onChange={(event) => setCurrentOptions(event.target.value)}>
                    <option value="">Aucun</option>
                    {messagesType.map((item) => <option value={item._id} key={item._id}>{item.title}</option>)}
                </SelectField>
            </div>
            <div className={"cta-copy"} onClick={()=> _onSelect()}>
                <FontAwesomeIcon icon={faShare}/>
                Insérer
            </div>
        </div>
    )
};
export default ListTypeMessages;